import React from "react"
import Img from "gatsby-image"
import { graphql } from 'gatsby'
// import { FaBullseye } from 'react-icons/fa';

import Layout from "../components/layout"
import SEO from "../components/seo"

// const context = "our-mission"
const seoTitle = "Our Mission"

const OurMissionPage = ({data}) => (
    <Layout>
        <SEO title={seoTitle}/>
        <div className="container">            
            <div className="row article-banner">
                <div className="col">
                    <Img fluid={data.bannerql.childImageSharp.fluid} />
                </div>
            </div>
            <div className="row article-heading">
                <div className="col">
                    <h3><span className="label label-primary">{data.markupql.edges[0].node.frontmatter.heading}</span></h3>
                </div>
            </div>            
            <div className="row article-text">
                <div className="col">
                    <div
                        dangerouslySetInnerHTML={{ __html: data.markupql.edges[0].node.html }}
                    >
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)

export default OurMissionPage

export const query1 = graphql `
    query {
    bannerql: file(relativePath: {eq: "banners/our-mission-1.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1900) {
                ...GatsbyImageSharpFluid
            }
        }
    }

    markupql: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/our-mission/i"}}) {
        edges {
          node {
            frontmatter {
              heading
            }
            html
          }
        }
      }
}
`